<template>
  <div>
    <v-card
      flat
    >
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            ></v-col>
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="date_of_deposit"
                  label="Date Deposited"
                  dense
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  @change="key++"
                ></v-text-field>
              </v-col>

              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="time_of_deposit"
                  label="Time Deposited"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  @keyup="time_release_format"
                  maxlength="8"
                  dense
                  outlined
                  @change="key++"
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-select
                  v-model="bank_id"
                  dense
                  outlined
                  label="Bank Code"
                  :items="banks"
                  item-value="id"
                  item-text="bank_code_name"
                  :rules="rules.combobox_rule"
                  @change="selected_bank(banks[banks.map(function (x) {
              return x.id
            }).indexOf(bank_id)])"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="bank_account_no"
                  label="Bank Account #"
                  dense
                  outlined
                  readonly
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  dense
                  outlined
                  type="number"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  @change="key++"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      bank_account_no: '',

      saving: false,
      alert: false,
      alert_message: '',

      banks: [],
      bank_id: 0,
      date_of_deposit: '',
      time_of_deposit: '',
      amount: 0,
      key: 0,
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    mounted() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    watch: {
      key: function () {
        this.$emit('data', {
          is_validated: this.$refs.form.validate(),
          bank_id: this.bank_id,
          date_of_deposit: this.date_of_deposit,
          time_of_deposit: this.time_of_deposit,
          amount: this.amount,
        })
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'user_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('bank_depository', ['find_bank_active']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      time_release_format() {
        this.time_of_deposit = this.time_formatter(this.time_of_deposit)
      },
      time_formatter(time_in) {
        return time_in = time_in.replace(/[^0-9]/g, '')
          .replace(/^(\d{2})?(\d{2})?(\d{2})/g, '$1:$2:$3')
      },
      initialize_data() {
        this.find_bank_active()
          .then(response => {
            this.banks = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_bank(data) {
        this.bank_account_no = data.account_no;
        this.key++
      },

    }
  }
</script>
